$primary_color: #545A34;
$primary_light_color: #64886A;
$active_item_background_primary:#545A341a;
$color-primary: $primary_color; // Add alias for compatibility

$warn_color: #F0C34E;
$success_color: #3BB273;
$error_color: #E15554;

$veggie_icon_color: #34693F;

$input_outline_gray: #79747E;
$input_suporting_text_gray: #79747E;
$disabled_gray: #979797;
$disabled_light_gray: #D9D9D9;
$txt_gray: #8E8E8E;
$inactive_text_gray: #979797;
$inactive_btn_gray: #979797;
$card_background_gray: #F4F4F4;

$snackbar_background_color: #322F35;

// Navigation
$nav-icon-size-small: 18px;
$nav-icon-size-medium: 22px;
$nav-icon-size-large: 24px;

$nav-text-size-small: 12px;
$nav-text-size-medium: 13px;
$nav-text-size-large: 14px;

$nav-item-height-small: 32px;
$nav-item-height-medium: 36px;
$nav-item-height-large: 40px;

// Font Text
$font_text: "Poppins", sans-serif;

$icon_size_sm: 16px;
$icon_size_md: 20px;
$icon_size_lg: 24px;
