@import 'vars';

// Icon sizes
.icon-sm {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
}

.icon-md {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
}

.icon-lg {
    width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
}

// Navigation Icons
.nav-icon {
  font-size: $nav-icon-size-small;
  width: $nav-icon-size-small;
  height: $nav-icon-size-small;
  color: #9faaa1;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon-medium {
  @extend .nav-icon;
  font-size: $nav-icon-size-medium;
  width: $nav-icon-size-medium;
  height: $nav-icon-size-medium;
}

.nav-icon-large {
  @extend .nav-icon;
  font-size: $nav-icon-size-large;
  width: $nav-icon-size-large;
  height: $nav-icon-size-large;
}

.nav-icon-active {
  color: var(--primary-color);
}

// Navigation Text
.nav-text {
  font-size: $nav-text-size-small;
  font-weight: 400;
  color: #9faaa1;
  margin: 0;
  transition: color 0.2s ease;
}

.nav-text-medium {
  @extend .nav-text;
  font-size: $nav-text-size-medium;
}

.nav-text-large {
  @extend .nav-text;
  font-size: $nav-text-size-large;
}

.nav-text-active {
  font-weight: 500;
  color: var(--primary-color);
}

// Navigation Items
.nav-item {
  height: $nav-item-height-small;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: #ecf5ed;

    .nav-icon {
      @extend .nav-icon-active;
    }

    .nav-text {
      @extend .nav-text-active;
    }
  }

  &.active:not(.disabled) {
    background-color: #ecf5ed;
    border-left: solid 4px var(--primary-color);

    .nav-icon {
      @extend .nav-icon-active;
    }

    .nav-text {
      @extend .nav-text-active;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.nav-item-medium {
  @extend .nav-item;
  height: $nav-item-height-medium;
}

.nav-item-large {
  @extend .nav-item;
  height: $nav-item-height-large;
}

// Icon containers
.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    
    mat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}