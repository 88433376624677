@import "./primeng-custom-theme.scss";
@import "./vars";
@import "./tables";
@import "./buttons";
@import "./typography";
@import "./forms";
@import "./spacing";
@import "./icons.scss";
@import "./snackbar";

:host ::ng-deep .mat-button-toggle .mat-pseudo-checkbox{
    display: none;
}

:host ::ng-deep .p-toast-detail{
    display: none;
}