@import './media-breakpoint';

//delivery tables
//vianda md table
.d-v-md-t {
    @include md {
        width: 45rem;
        padding-left: 10px;
        padding-right: 10px;
    }

    @include lg {
        width: 45rem;
        padding-left: 10px;
        padding-right: 10px;
    }

    @include xl {
        width: 60rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

//receipts md table
.d-r-md-t {
    @include md {
        width: 10rem;
    }

    @include lg {
        width: 10rem;
    }

    @include xl {
        width: 15rem;
    }

}

//table columns
.menu-column {
    @include md {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 80% !important;
    }
}

.drink-column {
    @include md {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
    }
}

.sm-column {
    @include md {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 40px !important;
    }
}

.md-column {
    @include md {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 80px !important;
    }
}

.lg-column {
    @include md {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 130px !important;
    }
}

//row height
.row-h-2 {
    @include md {
        height: 28px !important;
    }

    @include lg {
        height: 32px !important;
    }

    @include xl {
        height: 32px !important;
    }
}
