/* Add application styles & imports to this file! */
/* @use '~@angular/material' as mat; */
// @use "@angular/material" as mat;
@use "./buttons" as buttons;
@use "./forms" as forms;
@use "./cards" as cards;
// @import "~@angular/material/theming";
@import "./media-breakpoint";

$black: #000000;
$white: #ffffff;

$black_high_emphasis: rgba(64, 64, 72, 1);
$black_medium_emphasis: rgba(64, 64, 72, 0.6);
$black_inactive: rgba(64, 64, 72, 0.54);
$black_disabled: rgba(64, 64, 72, 0.38);
$black_focus_overlay: rgba(64, 64, 72, 0.38);

$white_high_emphasis: rgba(255, 255, 255, 1);
$white_medium_emphasis: rgba(255, 255, 255, 0.7);
$white_inactive: rgba(255, 255, 255, 0.54);
$white_disabled: rgba(255, 255, 255, 0.5);

$input_background: #f0f0f0;

$color_primary: #4fadb1;
$color_primary_hover: #4fadb1;
$color_primary_light: #a7d6d8;
$color_primary_active: #4fadb1;

$color_success: #57c691;
$color_success_hover: #57c691;
$color_warn: #ebc84c;
$color_warn_hover: #ebc84c;
$color_error: #eb4c60;
$color_error_hover: #eb4c60;
$color_background: #f8f8f8;
$color_background_hover: #f8f8f8;

$color_text_grey: #c9c9c9;

// Font Text
$font_text: "Mulish", sans-serif;
