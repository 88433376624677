.square-button-lg {
    height: 34px;
    width: 34px;
}

.square-button-md {
    height: 24px;
    width: 24px;
}

.square-button-sm {
    height: 16px;
    width: 16px;
}

.button-lg {
    height: 34px;
}

// :host ::ng-deep .button-pdf {
//     border-radius: 4px;
//     background-color: #545A34;
//     color: #FFFFFF;
//     width: 52px !important;
//     height: 34px !important;

//     .mat-icon {
//         font-size: 20px !important;
//         width: 20px !important;
//         height: 20px !important;
//         margin: 0 !important;
//     }
// }

:host ::ng-deep .icon-button-sm {
    padding: 0 !important;

    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 16px;
        width: 16px !important;
        height: 16px !important;

        .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {

            width: 16px !important;
            height: 16px !important;
        }
    }
}

:host ::ng-deep .icon-button-md {
    padding: 0px !important;
    width: 24px !important;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 24px !important;
        width: 24px !important;
        height: 24px !important;

        .mat-mdc-icon-button .mat-mdc-button-touch-target {
            width: 24px !important;
            height: 24px !important;
        }

        .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {

            width: 24px !important;
            height: 24px !important;
        }
    }
}


:host ::ng-deep .icon-button-lg {
    transform: scale(1.4);

    // display: flex !important;
    // align-items: center !important;
    // justify-content: center !important;

    // .mat-mdc-icon-button.mat-mdc-button-base {
    //     padding: 0px !important;
    //     --mdc-icon-button-state-layer-size: 34px !important;
    //     height: 34px !important;
    //     width: 34px !important;

    //     .mat-mdc-icon-button .mat-mdc-button-touch-target {
    //         height: 34px !important;
    //         width: 34px !important;
    //     }

    //     .mat-mdc-icon-button .mat-mdc-focus-indicator{
    //         height: 34px !important;
    //         width: 34px !important;
    //     }
    // }


}