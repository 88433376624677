.input-md{
    height: 34px;
}

:host ::ng-deep .externo-datepicker{
    .mat-form-field {
      height: 34px !important;
  
      .mat-mdc-text-field-wrapper .mdc-text-field .mdc-text-field--outlined {
        height: 34px !important;
  
        .mat-mdc-form-field-flex {
          height: 34px !important;
  
          .mdc-notched-outline {
            height: 34px !important;
  
            .mat-mdc-form-field-infix {
              padding: 0 !important;
              height: 34px !important;
            }
          }
  
          .mat-mdc-icon-button .mat-mdc-button-touch-target {
            height: 34px !important;
            width: 34px !important;
          }
        }
      }
    }
  }