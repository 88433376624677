//GLOBAL STYLES NUEVOS

.display-txt {
    font-family: $font_text;
    font-size: 20px;
    font-weight: 600; //semi-bold
    line-height: 30px;
}

.headline-large-txt {
    font-family: $font_text;
    font-size: 18px;
    font-weight: 700; //bold
    line-height: 27px;
}

.headline-small-txt {
    font-family: $font_text;
    font-size: 14px;
    font-weight: 500; //medium
    line-height: 21px;
}

.title-txt {
    font-family: $font_text;
    font-size: 12px;
    font-weight: 600; //semi-bold
    line-height: 18px;
}

.body-txt {
    font-family: $font_text;
    font-size: 11px;
    font-weight: 400; //regular
    line-height: 16.5px;
}

.label-txt {
    font-family: $font_text;
    font-size: 11px;
    font-weight: 400; //regular
    line-height: 16.5px;
}

.input-txt {
    font-family: $font_text;
    font-size: 14px;
    font-weight: 400;//regular
    line-height: 18px;
}

.button-txt {
    font-family: $font_text;
    font-size: 11px;
    font-weight: 700; //bold
    line-height: 16.5px;
}

.caption-txt {
    font-family: $font_text;
    font-size: 10px;
    font-weight: 400; //regular
    line-height: 15px;
}