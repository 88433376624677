@import "./vars";

h1 {
  font-family: $font_text;
  font-size: calc(16px + 2vmin);
  line-height: 1.4;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

h2 {
  font-family: $font_text;
  font-size: calc(14px + 2vmin);
  letter-spacing: -0.5px;
  line-height: 1.4;
  font-weight: 400;
}

h3 {
  font-family: $font_text;
  font-size: calc(8px + 2vmin);
  margin: 0px 0;
  letter-spacing: -0px;
  line-height: 1.4;
  font-weight: 400;
}

h4 {
  font-family: $font_text;
  font-size: 1.3em;
  margin-bottom: 16px;
  letter-spacing: 0.25px;
  line-height: 51px;
  font-weight: 400;
}

h5 {
  font-family: $font_text;
  font-size: calc(10px + 2vmin);
  letter-spacing: 0px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 0;
}

h6 {
  font-family: $font_text;
  font-size: 20px;
  letter-spacing: 0.25px;
  line-height: 30px;
  font-weight: 600;
  margin: 0.8em 0;
}

p,
body {
  font-family: $font_text;
 /*  font-size: 18px; */
  letter-spacing: 0.25px;
  line-height: 24px;
  font-weight: 400;
}

.body-2 {
  font-family: $font_text;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  font-weight: 400;
}

.subtitle-1 {
  font-family: $font_text;
  font-size: 16px;
  letter-spacing: 0.10px;
  line-height: 24px;
  font-weight: 400;
}

.subtitle-2 {
  font-family: $font_text;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 21px;
  font-weight: 600;
}

button {
  font-family: $font_text ;
  font-size: 13px ;
  letter-spacing: 0.35px ;
  font-weight: 800 ;
}

.caption {
  font-family: $font_text;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 18px;
  font-weight: 400;
}

.overline {
  font-family: $font_text;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
  

:root{
  --mat-standard-button-toggle-label-text-size: 12px;
  --mat-standard-button-toggle-label-text-weight : 400;
}