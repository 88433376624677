//MARGIN

//margin 0px

.m-l-0 {
    margin-left: 0px !important;
}

.m-r-0 {
    margin-right: 0px !important;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-b-0 {
    margin-bottom: 0px!important;
}

//margin 2px

.m-l-2 {
    margin-left: 2px !important;
}

.m-r-2 {
    margin-right: 2px !important;
}

.m-t-2 {
    margin-top: 2px !important;
}

.m-b-2 {
    margin-bottom: 2px !important;
}

//margin 4px

.m-l-4 {
    margin-left: 4px !important;
}

.m-r-4 {
    margin-right: 4px !important;
}

.m-t-4 {
    margin-top: 4px !important;
}

.m-b-4 {
    margin-bottom: 4px !important;
}

//margin 8px

.m-l-8 {
    margin-left: 8px !important;
}

.m-r-8 {
    margin-right: 8px !important;
}

.m-t-8 {
    margin-top: 8px !important;
}

.m-b-8 {
    margin-bottom: 8px !important;
}

//margin 12px
.m-l-12 {
    margin-left: 12px !important;
}

.m-r-12 {
    margin-right: 12px !important;
}

.m-t-12 {
    margin-top: 12px !important;
}

.m-b-12 {
    margin-bottom: 12px !important;
}

//margin 16px
.m-l-16 {
    margin-left: 16px !important;
}

.m-r-16 {
    margin-right: 16px !important;
}

.m-t-16 {
    margin-top: 16px !important;
}

.m-b-16 {
    margin-bottom: 16px !important;
}

//margin 20px

.m-l-20 {
    margin-left: 20px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

//margin 24px
.m-l-24 {
    margin-left: 24px !important;
}

.m-r-24 {
    margin-right: 24px !important;
}

.m-t-24 {
    margin-top: 24px !important;
}

.m-b-24 {
    margin-bottom: 24px !important;
}

//margin 32px
.m-l-32 {
    margin-left: 32px !important;
}

.m-r-32 {
    margin-right: 32px !important;
}

.m-t-32 {
    margin-top: 32px !important;
}

.m-b-32 {
    margin-bottom: 32px !important;
}

//margin 40px
.m-l-40 {
    margin-left: 40px !important;
}

.m-r-40 {
    margin-right: 40px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}


//PADDING

//padding 4px

.p-l-4 {
    padding-left: 4px !important;
}

.p-r-4 {
    padding-right: 4px !important;
}

.p-t-4 {
    padding-top: 4px !important;
}

.p-b-4 {
    padding-bottom: 4px !important;
}

//padding 8px
.p-l-8 {
    padding-left: 8px !important;
}

.p-r-8 {
    padding-right: 8px !important;
}

.p-t-8 {
    padding-top: 8px !important;
}

.p-b-8 {
    padding-bottom: 8px !important;
}

//padding 12px
.p-l-12 {
    padding-left: 12px !important;
}

.p-r-12 {
    padding-right: 12px !important;
}

.p-t-12 {
    padding-top: 12px !important;
}

.p-b-12 {
    padding-bottom: 12px !important;
}

//padding 16px
.p-l-16 {
    padding-left: 16px !important;
}

.p-r-16 {
    padding-right: 16px !important;
}

.p-t-16 {
    padding-top: 16px !important;
}

.p-b-16 {
    padding-bottom: 16px !important;
}

//padding 24px
.p-l-24 {
    padding-left: 24px !important;
}

.p-r-24 {
    padding-right: 24px !important;
}

.p-t-24 {
    padding-top: 24px !important;
}

.p-b-24 {
    padding-bottom: 24px !important;
}

//padding 32px
.p-l-32 {
    padding-left: 32px !important;
}

.p-r-32 {
    padding-right: 32px !important;
}

.p-t-32 {
    padding-top: 32px !important;
}

.p-b-32 {
    padding-bottom: 32px !important;
}

//padding 40px
.p-l-40 {
    padding-left: 40px !important;
}

.p-r-40 {
    padding-right: 40px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}