:root{
    --mdc-dialog-subhead-weight: 400;
}

::ng-deep .mat-mdc-dialog-title{
    padding: 0px!important;
}

::ng-deep .mat-mdc-dialog-container .mat-mdc-dialog-content{
    padding: 0px!important;
}