$primary_color: #545A34;
$primary_light_color: #64886A;
$active_item_background_primary:#545A341a;

$warn_color: #F0C34E;
$success_color: #3BB273;
$error_color: #E15554;

$veggie_icon_color: #34693F;

$input_outline_gray: #79747E;
$input_suporting_text_gray: #79747E;
$disabled_gray: #979797;
$disabled_light_gray: #D9D9D9;
$txt_gray: #8E8E8E;
$inactive_text_gray: #979797;
$inactive_btn_gray: #979797;
$card_background_gray: #F4F4F4;

$snackbar_background_color: #322F35;


// Font Text
$font_text: "Poppins", sans-serif;


$icon_size_sm: 16px;
$icon_size_md: 20px;
$icon_size_lg: 24px;

