@use '@angular/material' as mat;
@use '../../lib/styles/theme' as m3-theme;
@import "../../lib/assets/styles/global-styles.scss";


html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow: hidden;
  @include mat.core-theme(m3-theme.$light-theme);
  @include mat.button-theme(m3-theme.$light-theme);

  //PrimeNG UI component use rem units
  font-size: 14px;
}