::ng-deep .mat-mdc-tab-links {
    height: 36px !important;
}

::ng-deep .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link{
    height: 36px !important;
    font-weight: 600 !important;
    flex-grow: 0 !important;
}

::ng-deep .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
    flex-grow: 0 !important;
}

::ng-deep .tab-title{
    font-size: 12px !important;
}

