@use "@angular/material" as mat;

@import "../../lib/assets/styles/global-styles.scss";

// @import "primeng/resources/themes/lara-light-green/theme.css";
@import "primeng/resources/primeng.min.css";
@import 'primeicons/primeicons.css';
@import "./assets/styles/_typography.scss";
@import "./assets/styles/media-breakpoint";
@import "./assets/styles/global-styles.scss";
@import "./assets/styles/table-styles.scss";
@import "./assets/styles/form-fields.scss";
@import "./assets/styles/menus.scss";
@import "./assets/styles/tabs.scss";
@import "./assets/styles/checkboxes.scss";
@import "./assets/styles/dialogs.scss";
@import "./assets/styles/flex.scss";
// @import './assets/styles/primeng-custom-theme.scss';

@include mat.core();

@include mat.form-field-density(-3);
@include mat.all-component-densities(-3);

:root {
  --primary-color: #64886a;
  --primary-color-013: rgb(100, 136, 106, 0.129);
  --secundary-color: #00a5cf;
  --error-color: #ee2e31;
  --error-color-013: rgba(238, 46, 49, 0.129);
  --warning-color: #ff9914;
  --warning-color-013: #ff991421;
}

html,
body {
  height: 100%;
  width: 100%;
  /* Safari/Chrome, other WebKit */
  -webkit-box-sizing: border-box;
  /* Firefox, other Gecko */
  -moz-box-sizing: border-box;
  /* Opera/IE 8+ */
  box-sizing: border-box;
  margin: 0;
  overflow: auto;

}

.mat-menu-panel {
  min-height: unset !important;
}

.menu-list {
  .mat-button-toggle-label-content {
    font-size: 12px !important;
    line-height: 24px !important;
  }
}

.draggable-menu-type-preview {
  width: 100%;
  padding: 4px;
  border: solid 1px rgb(221, 221, 221);
  color: rgba(0, 0, 0, 0.87);
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr 0.2fr;
  justify-items: center;
  // box-sizing: border-box;
  cursor: move;
  // background: white;
  // font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  &:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    // background-color: blueviolet;
  }

  h4 {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
    // font-weight: 600;
  }

  .icnBtn {
    height: 20px;
    width: 20px;
    line-height: 20px;
    background-color: red;
    .mat-icon {
      line-height: 18px;
      height: 18px !important;
      width: 18px !important;
      font-size: 18px !important;
    }
  }

  .mat-icon {
    justify-self: baseline;
    color: #787878;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
    font-size: 20px !important;
  }
}

/* ////////////////////////// MENU ITEMS ////////////////////////// */
.customMenu + * .cdk-overlay-pane {
  padding: 0px !important;

  .mat-menu-content:not(:empty) {
    padding: 0px !important;
  }

  .mat-mdc-menu-item .mat-icon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 18px;
  }

  .mat-menu-item {
    height: 2rem !important;
    font-size: 0.8rem !important;
    line-height: 1rem !important;
    padding: 8px 8px 8px 8px !important;
  }
}

.mat-menu-panel.menuSection {
  margin: -24px 0 0px 34px;
}

/* ////////////////////////// SCROLLBAR ////////////////////////// */

::-webkit-scrollbar {
  width: 6px;
  max-height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(87, 87, 87, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(87, 87, 87, 0.4);
}

/* ////////////////////////// NO SELECT TEXT ////////////////////////// */

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* ////////////////////////// CALENDAR ////////////////////////// */

.date-with-task {
  border-radius: 50px;
  background-color: rgb(100, 136, 106, 0.15);
}

.calendar-tasks {
  .mat-calendar-header {
    padding: 0px;

    .mat-calendar-controls {
      margin-top: 0px;
    }
  }
}

.menuItem {
  font-size: 12px !important;
  line-height: 32px !important;
  vertical-align: middle !important;
  font-weight: 500 !important;
  margin: 4px 0 !important;
  padding: 0 8px !important;
  height: 32px !important;
}

$butaco-primary: (
  50: #ecf1ed,
  100: #d1dbd2,
  200: #b2c4b5,
  300: #93ac97,
  400: #7b9a80,
  500: #64886a,
  600: #5c8062,
  700: #527557,
  800: #486b4d,
  900: #36583c,
  A100: #abffb8,
  A200: #78ff8c,
  A400: #45ff61,
  A700: #2bff4b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$butaco-error: (
  50: #fde6e6,
  100: #fac0c1,
  200: #f79798,
  300: #f36d6f,
  400: #f14d50,
  500: #ee2e31,
  600: #ec292c,
  700: #e92325,
  800: #e71d1f,
  900: #e21213,
  A100: #ffffff,
  A200: #ffdddd,
  A400: #ffaaaa,
  A700: #ff9091,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$butaco-warn: (
  50: #fff3e3,
  100: #ffe0b9,
  200: #ffcc8a,
  300: #ffb85b,
  400: #ffa837,
  500: #ff9914,
  600: #ff9112,
  700: #ff860e,
  800: #ff7c0b,
  900: #ff6b06,
  A100: #ffffff,
  A200: #fff7f3,
  A400: #ffd6c0,
  A700: #ffc6a7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Primary
$butaco-front-primary: mat.m2-define-palette($butaco-primary);

// Error
$butaco-front-accent: mat.m2-define-palette($butaco-error);
// Warn
$butaco-front-warn: mat.m2-define-palette($butaco-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$butaco-front-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $butaco-front-primary,
      accent: $butaco-front-accent,
      warn: $butaco-front-warn,
    ),
    density: (
      scale: -3
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
$custom-typography: mat.m2-define-typography-config(
  $font-family: "'Open Sans', sans-serif",
);
/* @include mat.all-component-typographies($custom-typography);

@include mat.all-component-themes($butaco-front-theme); */

// DarkMode

// Define an alternate dark theme.
$dark-primary: mat.m2-define-palette($butaco-primary);
$dark-accent: mat.m2-define-palette($butaco-error);
$dark-warn: mat.m2-define-palette($butaco-warn);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
    density: (
      scale: -5
    )
  )
);

// Include the dark color styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dark-theme {
  @include mat.all-component-colors($dark-theme);
}

$custom-typography: mat.m2-define-typography-config(
  $font-family: "'Poppins', sans-serif",
  $headline-1:
    mat.m2-define-typography-level(
      clamp(9px, 2.4vw, 100px),
      clamp(9px, 1vw, 100px),
      300,
      $letter-spacing: -0.05em
    ),
  $headline-2:
    mat.m2-define-typography-level(
      clamp(9px, 2vw, 100px),
      clamp(9px, 1vw, 100px),
      400,
      $letter-spacing: -0.02em
    ),
  $headline-3:
    mat.m2-define-typography-level(
      clamp(9px, 1.6vw, 100px),
      clamp(9px, 1vw, 100px),
      400,
      $letter-spacing: -0.005em
    ),
  $headline-4:
    mat.m2-define-typography-level(
      clamp(9px, 1.4vw, 100px),
      clamp(9px, 1vw, 100px),
      400
    ),
  $headline-5:
    mat.m2-define-typography-level(
      clamp(9px, 1.2vw, 100px),
      clamp(9px, 1vw, 100px),
      400
    ),
  $headline-6:
    mat.m2-define-typography-level(
      clamp(9px, 1.1vw, 100px),
      clamp(9px, 1vw, 100px),
      500
    ),
  $subtitle-1:
    mat.m2-define-typography-level(
      clamp(9px, 0.9vw, 100px),
      clamp(9px, 1vw, 100px),
      400
    ),
  $subtitle-2:
    mat.m2-define-typography-level(
      clamp(9px, 0.8vw, 100px),
      clamp(9px, 0.9vw, 100px),
      400
    ),
  $body-1:
    mat.m2-define-typography-level(
      clamp(9px, 0.8vw, 100px),
      clamp(9px, 0.8vw, 100px),
      600
    ),
  $body-2:
    mat.m2-define-typography-level(
      clamp(9px, 0.7vw, 100px),
      clamp(9px, 0.7vw, 100px),
      400
    ),
  $caption:
    mat.m2-define-typography-level(
      clamp(9px, 0.6vw, 100px),
      clamp(9px, 0.6vw, 100px),
      400
    ),
  $button:
    mat.m2-define-typography-level(
      clamp(9px, 0.9vw, 100px),
      clamp(9px, 0.9vw, 100px),
      500
    ),
  $overline:
    mat.m2-define-typography-level(
      clamp(9px, 0.6vw, 100px),
      clamp(9px, 0.6vw, 100px),
      400
    ),
);

@include mat.all-component-typographies($custom-typography);

@include mat.all-component-themes($butaco-front-theme);

.dialog-butaco {
  border-radius: 1rem;
}

.dialog-butaco-request .mat-dialog-container {
  overflow-y: auto;
}

.element-row {
  height: 0;

  @include lg {
    height: 50px;
  }
}

.mat-tab-label-content {
  * {
    font-size: 12px !important;
    font-weight: 600;
  }
}

.content-list {
  li {
    @include lg {
      height: 50px;
    }
  }
}

::ng-deep .mat-mdc-dialog-surface {
  padding: 24px!important;
}
