::ng-deep .mat-mdc-menu-item-text{
    font-size: 12px !important;
    line-height: 32px !important;
    vertical-align: middle !important;
    font-weight: 500 !important;
    margin: 4px 0 !important;
    padding: 0 8px !important;
    height: 32px !important;
}

::ng-deep .mat-mdc-option{
    font-size: 10px !important;
}

::ng-deep .mat-mdc-paginator .mat-mdc-select{
    margin: 5px;
    
}

::ng-deep .mat-mdc-select{
    font-weight: 400 !important;
}