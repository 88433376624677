@import "./media-breakpoint";

//first try
$xs-title-font-size: 9px;
$sm-title-font-size: 12px;
$md-title-font-size: 16px;

$text-font-size: 12px;
$sm-text-font-size: 9px;
$xs-text-font-size: 8px;

$xs-title-font-size: 9px;
$sm-title-font-size: 10px;
$md-title-font-size: 14px;


//global styles
$lg-text: 14px;
$md-text: 12px;
$sm-text: 11px;
$sm-text-2: 10px;
$xs-text: 9px;
$xxs-text: 8px;

$sm-title: 16px;

$xs-icon: 14px;
$sm-icon: 16px;
$md-icon: 18px;

.text-xs {
  @include md {
    font-size: $xxs-text !important;
  }

  @include lg {
    font-size: $xs-text !important;
  }

  @include xl {
    font-size: $xs-text !important;
  }
}

.text-sm {
  @include md {
    font-size: $sm-text-2 !important;
  }

  @include lg {
    font-size: $sm-text !important;
  }

  @include xl {
    font-size: $sm-text !important;
  }
}

.text-md {
  @include md {
    font-size: $sm-text !important;
  }

  @include lg {
    font-size: $md-text !important;
  }

  @include xl {
    font-size: $md-text !important;
  }
}

.text-lg {
  @include md {
    font-size: $md-text !important;
  }

  @include lg {
    font-size: $lg-text !important;
  }

  @include xl {
    font-size: $lg-text !important;
  }
}

.title-sm {
  @include md {
    font-size: $md-text !important;
  }

  @include lg {
    font-size: $lg-text !important;
  }

  @include xl {
    font-size: $sm-title !important;
  }
}

.icon-sm {
  padding: 0px;
  @include md {
    font-size: $md-icon !important;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
  }

  @include lg {
    font-size: $md-icon !important;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
  }

  @include xl {
    font-size: $md-icon !important;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
    margin: 5px !important;
  }
}

.icon-md {
  padding: 0px;
  @include md {
    font-size: 20px;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
  }

  @include lg {
    font-size: 22px !important;
    line-height: 22px;
    height: 22px !important;
    width: 22px !important;
  }

  @include xl {
    font-size: 24px !important;
    line-height: 24px;
    height: 24px !important;
    width: 24px !important;
  }
}

::ng-deep .mat-mdc-icon-button .mat-mdc-button-touch-target {
  @include md {
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
  }

  @include lg {
    height: 22px !important;
    width: 22px !important;
    line-height: 22px !important;
  }

  @include xl {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
  }
}

.icnBtn-sm {
  padding: 0px !important;

  @include md {
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
  }

  @include lg {
    height: 22px !important;
    width: 22px !important;
    line-height: 22px !important;
  }

  @include xl {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
  }
}

.logo-full {
  @include md {
    height: 60px;
    min-width: 30px;
  }

  @include lg {
    height: 60px;
    min-width: 50px;
    margin: -6px;
  }

  @include xl {
    height: 67px;
    min-width: 50px;
    margin: 5px;
  }
}

.logo-short {
  @include md {
    max-width: 40px;
    //width: 30px;
  }

  @include lg {
    padding: 0;
    max-width: 40px;
    width: 35px;
  }

  @include xl {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    max-width: 60px;
    width: 40px;
  }
}

//margin

.m-l-1 {
  @include md {
    margin-left: 16px !important;
  }

  @include lg {
    margin-left: 24px !important;
  }

  @include xl {
    margin-left: 24px !important;
  }
}

.m-r-1 {
  @include md {
    margin-right: 16px !important;
  }

  @include lg {
    margin-right: 24px !important;
  }

  @include xl {
    margin-right: 24px !important;
  }
}

.m-b-1 {
  @include md {
    margin-bottom: 16px !important;
  }

  @include lg {
    margin-bottom: 24px !important;
  }

  @include xl {
    margin-bottom: 24px !important;
  }
}

.m-t-1 {
  @include md {
    margin-top: 16px !important;
  }

  @include lg {
    margin-top: 24px !important;
  }

  @include xl {
    margin-top: 24px !important;
  }
}

//line height

.l-h-2 {
  @include md {
    line-height: 8.5px !important;
  }

  @include lg {
    line-height: 8.5px !important;
  }

  @include xl {
    line-height: 16.5px !important;
  }
}

.l-h-3 {
  @include md {
    line-height: 12px !important;
  }

  @include lg {
    line-height: 12px !important;
  }

  @include xl {
    line-height: 18px !important;
  }
}

.l-h-4 {
  @include md {
    line-height: 28px !important;
  }

  @include lg {
    line-height: 32px !important;
  }

  @include xl {
    line-height: 32px !important;
  }
}

.l-h-5 {
  @include md {
    line-height: 30px !important;
  }

  @include lg {
    line-height: 35px !important;
  }

  @include xl {
    line-height: 35px !important;
  }
}

// .logo-short{

// }

.text-xs {
  font-size: $xs-text-font-size;
}

.md-title {
  font-size: $md-title-font-size;
}

.sm-title {
  font-size: $sm-title-font-size;
}

.xs-title {
  font-size: $xs-title-font-size;
}
