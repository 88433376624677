:root {
  //tamaño
  --mat-form-field-container-height: 32px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;

  //borde
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.28);

  //texto
  --mdc-filled-text-field-label-text-font: "Poppins", sans-serif;
  --mdc-filled-text-field-label-text-size: clamp(9px, 0.8vw, 100px);
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: "Poppins", sans-serif;
  --mdc-outlined-text-field-label-text-size: clamp(9px, 0.8vw, 100px);
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: "Poppins", sans-serif;
  --mat-form-field-container-text-line-height: clamp(9px, 0.8vw, 100px);
  --mat-form-field-container-text-size: clamp(9px, 0.8vw, 100px);
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: clamp(9px, 0.8vw, 100px);
  --mat-form-field-subscript-text-font: "Poppins", sans-serif;
  --mat-form-field-subscript-text-line-height: clamp(9px, 0.6vw, 100px);
  --mat-form-field-subscript-text-size: clamp(9px, 0.6vw, 100px);
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;

  //esto mueve el icono de adentro
  --mat-mdc-form-field-label-offset-x: -12px!important;
}

::ng-deep .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  --mat-mdc-form-field-label-offset-x: -12px!important;
}