@import "./vars";

:host ::ng-deep .consumption-png-table {
    .p-table-text {
        font-size: 12px;
        font-family: "Poppins";

        p {
            text-transform: lowercase !important;
        }

        p::first-letter {
            text-transform: uppercase !important;
        }
    }

    p {
        font-size: 12px !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .header-row {
        display: flex;
        align-items: center;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0px 5px;

        .p-button {
            padding: 5px !important;
            font-size: 12px !important;
        }

        .p-button .p-button-label {
            font-size: 12px !important;
        }

        .export-button .p-button-label {
            color: #ffffff !important;
        }
    }

    .active-label,
    .pending-label,
    .inactive-label {
        display: flex;
        align-items: center;
    }

    .status-column {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .mat-icon {
        font-size: 18px !important;
        height: 18px !important;
        width: 18px !important;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .active-label {
        color: $success_color;
    }

    .pending-label {
        color: $warn_color;
    }

    .inactive-label {
        color: $error_color;
    }

    .user-status {
        display: flex;
        align-items: center;
        vertical-align: center;

        .user-status-icon {
            display: flex;
            justify-content: center;
            vertical-align: center;
        }
    }

    .total-consumptions {
        font-size: 14px;
        font-family: "Poppins";
        text-wrap: nowrap;

        span {
            font-size: 16px;
            color: #000;
        }
    }

    .no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 50vh;

        .mat-icon {
            font-size: 5rem !important;
            height: 100px !important;
            width: 100px !important;
        }
    }

    .mat-mdc-icon-button.mat-mdc-button-touch-target {
        width: 20px !important;
        height: 20px !important;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 20px !important;
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
        margin: 0 !important;
    }

    .mat-mdc-icon-button .mat-mdc-focus-indicator {
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
        margin: 0 !important;
    }

    .p-paginator .p-dropdown {
        height: 2rem !important;
    }

    .p-datatable .p-datatable-thead>tr>th {
        padding: 2px 0px !important;
        font-size: 12px;
        font-family: 'poppins';
    }

    .p-datatable .p-datatable-tbody>tr>td {
        padding: 2px 0px !important;
        font-size: 12px;
        font-family: 'poppins';
    }

    .p-datatable .p-datatable-header {
        padding: 0px !important;
        font-size: 12px;
    }

    .p-datatable .p-paginator-bottom {
        // height: 50px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .p-paginator .p-paginator-first {
        height: 25px !important;
    }

    .p-paginator .p-paginator-prev {
        height: 25px !important;
    }

    .p-paginator .p-paginator-next {
        height: 25px !important;
    }

    .p-paginator .p-paginator-last {
        height: 25px !important;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 25px !important;
        // width: 25px !important;
        font-size: 12px !important;
    }

    .p-paginator-pages .ng-star-inserted {
        width: 25px !important;
    }

    .p-datatable .p-paginator-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

}