@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {

  ////////////////////////// BUTTONS //////////////////////////
  app-layout-landing {
    .mat-flat-button {
      border-radius: 0px !important;
      padding: 14px 32px !important;
      text-transform: none !important;

      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
      }
    }

    .mat-stroked-button {
      border-radius: 0px !important;
      border: solid 1px !important;
      padding: 14px 32px !important;
      text-transform: none !important;

      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
      }
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-stroked-button {
    min-width: 100px;
    padding: 8px 6px !important;
    text-transform: none !important;
    box-sizing: border-box;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      margin: 0;
    }
  }

  .mat-flat-button {
    border-radius: 0px !important;
  }

  .mat-stroked-button {
    border-radius: 0px !important;
    border: solid 1px !important;
  }

  mat-checkbox {
    .mat-checkbox-layout {
      white-space: normal !important;
    }
  }

  .mat-step-label.mat-step-label {
    text-overflow: inherit;
    white-space: normal;
  }
}

//  mat-button mat-button-base
::ng-deep .mat-icon {
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
  padding: 2px;
}

::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {
  height: 26px !important;
  width: 26px !important;
  padding: 0 !important;
}

.icon-big {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}

.mat-calendar-controls {

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    height: 40px !important;
    width: 40px !important;

  }

  .mat-calendar-previous-button::after {
    height: 8px !important;
    width: 8px !important;
  }

  .mat-calendar-next-button::after {
    height: 8px !important;
    width: 8px !important;
  }

  :root {
    --mdc-icon-button-state-layer-size: 48px !important;
    --mat-icon-button-touch-target-display: block !important;
  }



  .mat-mdc-focus-indicator {
    position: relative;
    width: var(--mdc-icon-button-state-layer-size) !important;
    height: var(--mdc-icon-button-state-layer-size) !important;
  }
}

::ng-deep .mdc-button__label{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: nowrap;
}

::ng-deep .mat-calendar-body-cell{
  font-weight: 500; 
}