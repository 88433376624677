/* :host ::ng-deep .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface.snack-bar-container.snackbar-success .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
    width: 423px;
    height: 56px;
}

:host ::ng-deep .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface.snack-bar-container.snackbar-success .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
    background-color: #3BB2731A;
    color: #3BB273;
    border-radius: 4px;
}


:host ::ng-deep .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface.snack-bar-container.snackbar-error.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
    width: 423px;
    height: 56px;
}

:host ::ng-deep .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface.snack-bar-container.snackbar-error .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
    color: #E15554;
    border-radius: 4px;
    background-color: #E155541A;
} */


:host ::ng-deep .mat-mdc-snack-bar-container.snackbar-error .mat-mdc-snackbar-surface {
    width: 423px;
    height: 56px;
    background-color: #E155541A;
    color: #E15554;
    border-radius: 4px;
}