:host ::ng-deep.icon-sm {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
}

:host ::ng-deep.icon-md {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 20px !important;
    height: 20px !important;
    font-size: 22px !important;
}

:host ::ng-deep.icon-lg {
    width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
}